jQuery(function($) {

  $(window).on('scroll', function() {
    let currentTop = $(window).scrollTop()
    let elm = $('.section-parallax')
    let footerOffset = $('#main-footer').offset().top
    let lastSecHeight = $('#p-05').outerHeight()
    if ($(this).scrollTop() >= (footerOffset - lastSecHeight + 300)) {
      $('.section-navigation').fadeOut(200)
    } else {
      $('.section-navigation').fadeIn(200)
    }
    elm.each(function() {
      let elemTop = $(this).offset().top - 200
      let elemBottom = elemTop + $(this).height()
      if (currentTop >= elemTop && currentTop <= elemBottom) {
        let id = $(this).attr('id')
        let navElem = $(`.section-navigation .section a[href="#${id}"]`)
        navElem.parent().addClass('active').siblings().removeClass('active')
      }
    })
  })

  $('.guide-box .button').on('click', function() {
    const parent = $(this).closest('.section-parallax')
    parent.addClass('is-full-height')
    parent.find('.guide-box').addClass('hide')
    parent.find('.guide-box-detail').addClass('show')
  })

  $('.guide-box-detail .close').on('click', function() {
    const parent = $(this).closest('.section-parallax')
    parent.removeClass('is-full-height')
    parent.find('.guide-box').removeClass('hide')
    parent.find('.guide-box-detail').removeClass('show')
  })

})
